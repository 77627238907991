const constants = require('./constants')

export function cleanKeyIfLocalhost(apiUrl, apiKey) {
  if (apiUrl?.includes('localhost')) {
    return apiKey?.replace(/\\/g, '')
  }
  return apiKey
}

export const config = {
  runtimeEnvironment: process.env.RUNTIME_ENVIRONMENT || 'test',
  data: {
    exConMongo: process.env.EXCON_MONGO_CONNECTION,
    exConDbName: process.env.EXCON_MONGO_DB_NAME || 'convo-extensions',
    exConReplicaSet: process.env.EXCON_MONGO_REPLICA_SET || '',
  },
  botMessageService: {
    participantSemaphore: process.env.PARTICIPANT_SEMAPHORE,
    participantSemaphoreExpiration: process.env.PARTICIPANT_SEMAPHORE_EXPIRATION,
  },
  /**
   * @deprecated
   */
  eventBot: {
    host: process.env.EVENT_BOT_HOST || 'https://bot-test.42chat.com/',
  },
  EVENTBOT: {
    HOST: process.env.EVENT_BOT_HOST,
  },
  HEROKU: {
    APP_NAME: process.env.HEROKU_APP_NAME || 'unknown-app',
  },
  facebook: {
    customerBotAppId: process.env.FACEBOOK_BOT_APP_ID,
    customerBotSecret: process.env.FACEBOOK_BOT_SECRET,
  },
  flagsmith: {
    environmentID: process.env.FLAGSMITH_ENVIRONMENT_ID,
  },
  google: {
    clientEmail: process.env.DIALOGFLOW_CLIENT_EMAIL,
    privateKey: process.env.DIALOGFLOW_PRIVATE_KEY || '',
    organizationId: process.env.GOOGLE_PROJECT_ID || '785073037645',
    DRIVE_KEYFILE: process.env.GOOGLE_DRIVE_KEYFILE,
    pubsubEmail: process.env.GOOGLE_PUBSUB_EMAIL,
    pubsubKey: cleanKeyIfLocalhost(process.env.SCIENSIO_API_URL, process.env.GOOGLE_PUBSUB_KEY),
    pubsubUrl: process.env.GOOGLE_PUBSUB_URL,
  },
  microsoft: {
    spellingApiKey: process.env.BING_SPELLCHECK_SUBSCRIPTION_KEY,
  },
  mindbody: {
    apiKey: process.env.MINDBODY_API_KEY,
    sourceName: process.env.MINDBODY_SOURCE_NAME,
    sourcePassword: process.env.MINDBODY_SOURCE_PASSWORD,
  },
  quickbooks: {
    environment: process.env.QUICKBOOKS_ENVIRONMENT || 'sandbox',
    clientId:
      process.env.QUICKBOOKS_ENVIRONMENT === 'production'
        ? process.env.QUICKBOOKS_PRODUCTION_CLIENT_ID
        : process.env.QUICKBOOKS_SANDBOX_CLIENT_ID,
    clientSecret:
      process.env.QUICKBOOKS_ENVIRONMENT === 'production'
        ? process.env.QUICKBOOKS_PRODUCTION_CLIENT_SECRET
        : process.env.QUICKBOOKS_SANDBOX_CLIENT_SECRET,
    redirectURI: process.env.QUICKBOOKS_REDIRECT_URI || 'http://localhost:8080/api/oauth/intuit/callback',
  },
  rabbit: {
    connectionUrl: process.env.CLOUDAMQP_URL || 'amqp://rabbitmq:rabbitmq@localhost',
    defaultExchangeName: constants.rabbitExchangeNames.sciensioDirect,
  },
  redis: {
    redisUrl: process.env.REDIS_URL,
  },
  ROCKSET: {
    API_KEY: process.env.ROCKSET_APIKEY,
    WORKSPACE: process.env.ROCKSET_WORKSPACE,
  },
  socket: {
    url: process.env.SCIENSIO_SOCKET_URL || 'http://127.0.0.1',
    port: process.env.SCIENSIO_SOCKET_PORT,
    serverKey: process.env.SCIENSIO_SOCKET_KEY,
    serverToken: process.env.SCIENSIO_SOCKET_TOKEN,
    serverUrls: process.env.SERVER_URLS,
    anonymousKey: process.env.SCIENSIO_ANONYMOUS_KEY,
    password: process.env.SOCKET_SERVER_PASSWORD,
  },
  logLevel: process.env.LOG_LEVEL || 'debug',
  postmark: {
    apiToken: process.env.POSTMARK_API_TOKEN,
    fromAddress: 'support@42chat.com',
  },
  revealWhy: {
    apiKey: process.env.REVEAL_WHY_API_KEY,
    baseUrl: process.env.REVEAL_WHY_BASE_URL || 'http://23.92.25.10/ldsc/',
  },
  services: {
    botScraperURL: process.env.BOT_SCRAPER_URL || 'https://bot-scraper-test.herokuapp.com',
  },
  sciensio: {
    baseApiUrl: process.env.SCIENSIO_API_URL || 'https://testapi.42chat.com',
    appUrl: process.env.SCIENSIO_APP_URL || 'http://localhost:3000',
    apiUserName: process.env.SCIENSIO_API_USER || 'dev@sciensio.com',
    apiUserPassword: process.env.SCIENSIO_API_PASSWORD,
    shortenerDomain: process.env.SCIENSIO_SHORTENER_DOMAIN || 'rt.42chat.com',
    webMessengerDomain: process.env.WEB_MESSENGER_DOMAIN || 'web-messenger-test.herokuapp.com',
  },
  security: {
    encryptionKey: process.env.ENCRYPTION_KEY || 'passwordpasswordpasswordpassword',
    jwtSecret: process.env.JWT_SECRET || 'NOT_SECRET',
    tokenValidLength: process.env.TOKEN_VALID_LENGTH || '7d',
    oneTimeTokenValidHours: process.env.ONE_TIME_TOKEN_VALID_HOURS | '96',
  },
  stripe: {
    secret: process.env.STRIPE_SECRET,
  },
  SLACK: {
    SLACK_CHANNEL_MESSAGING_ERRORS: process.env.SLACK_CHANNEL_MESSAGING_ERRORS,
    SYSTEM_SERVICES_USER_TOKEN: process.env.SLACK_SYSTEM_SERVICES_USER_TOKEN,
  },
  turing: {
    token: process.env.TURING_TOKEN || 'testing',
  },
  phoneNumberService: {
    loopWaitIntervalSeconds: process.env.LOOP_WAIT_INTERVAL_SECONDS || '10',
  },
  notifications: {
    participantPageSize: process.env.NOTIFICATIONS_PAGE_SIZE
      ? parseInt(process.env.NOTIFICATIONS_PAGE_SIZE, 10)
      : 20,
  },
  aws: {
    cloudfrontUrl: process.env.CLOUDFRONT_BASEURL || 'https://images.42chat.com',
    intakeStagingArea: process.env.INTAKE_STAGING_AREA || 'intakeStaging',
    kmsKeyId: process.env.AWS_KMS_KEY_ID,
    s3Bucket: process.env.S3_BUCKET,
    s3ImagesBucket: process.env.S3_IMAGES_BUCKET,
    s3Region: process.env.S3_REGION,
  },
  telnyx: {
    apiKey: process.env.TELNYX_API_KEY,
    messagingProfileId: process.env.TELNYX_MESSAGING_PROFILE_ID,
  },
  twilio: {
    testAccountSid: process.env.TWILIO_TEST_ACCOUNT_SID,
    testAuthToken: process.env.TWILIO_TEST_AUTH_TOKEN,
    accountSid: process.env.TWILIO_SID,
    authToken: process.env.TWILIO_AUTH,
  },
  rockSet: {
    workspace: process.env.ROCKSET_WORKSPACE || 'commons',
    apiKey: process.env.ROCKSET_APIKEY,
  },
  INBOUND_WEBHOOKS: {
    URL: process.env.INBOUND_WEBHOOK_SERVICE_URL,
  },
  STATUS_WEBHOOKS: {
    URL: process.env.STATUS_WEBHOOK_SERVICE_URL,
  },
  SMOOCH: {
    SECRET: process.env.ACCOUNT_SMOOCH_SECRET,
    KEY: process.env.ACCOUNT_SMOOCH_KEY,
    BASE_URI: process.env.ACCOUNT_SMOOCH_BASE_URI || 'https://api.smooch.io/v1/',
  },
  csp: {
    cspConnect: process.env.REACT_APP_CSP_CONNECT,
    cspFont: process.env.REACT_APP_CSP_FONT,
    cspFrame: process.env.REACT_APP_CSP_FRAME,
    cspImg: process.env.REACT_APP_CSP_IMG,
    cspScript: process.env.REACT_APP_CSP_SCRIPT,
    cspStyle: process.env.REACT_APP_CSP_STYLE,
    cspReportOnly: process.env.REACT_APP_CSP_REPORT_ONLY,
  },
  firebase: {
    chatPlatformClientEmail: process.env.FIREBASE_CHAT_PLATFORM_CLIENT_EMAIL,
    chatPlatformDatabaseUrl: process.env.FIREBASE_CHAT_PLATFORM_DATABASE_URL,
    chatPlatformPrivateKey: cleanKeyIfLocalhost(
      process.env.SCIENSIO_API_URL,
      process.env.FIREBASE_CHAT_PLATFORM_PRIVATE_KEY,
    ),
    chatPlatformProjectId: process.env.FIREBASE_CHAT_PLATFORM_PROJECT_ID,
    exconPlaceDatabaseUrl: process.env.FIREBASE_EXCON_STORE_DATABASE_URL,
    exconPlaceHost: process.env.REACT_APP_EXCON_PLACE_HOST,
    exconPlaceHttpsApi: process.env.FIREBASE_HTTPS_API,
    exconPlaceMessengerApi: process.env.FIREBASE_MESSENGER_API,
    exconPlaceRequestParticipant: process.env.FIREBASE_REQUEST_PARTICIPANT,
    exconStoreClientEmail: process.env.FIREBASE_EXCON_STORE_CLIENT_EMAIL,
    exconStorePrivateKey: cleanKeyIfLocalhost(
      process.env.SCIENSIO_API_URL,
      process.env.FIREBASE_EXCON_STORE_PRIVATE_KEY,
    ),
    exconStoreProjectId: process.env.FIREBASE_EXCON_STORE_PROJECT_ID,
  },
  snowflake: {
    account: process.env.SNOWFLAKE_ACCOUNT || '',
    username: process.env.SNOWFLAKE_USERNAME || '',
    password: process.env.SNOWFLAKE_PASSWORD || '',
    database: process.env.SNOWFLAKE_DATABASE || '',
    schema: process.env.SNOWFLAKE_SCHEMA || '',
    warehouse: process.env.SNOWFLAKE_WAREHOUSE || '',
  },
  postgres: {
    host: process.env.POSTGRES_HOST,
    port: process.env.POSTGRES_PORT || '5432',
    user: process.env.POSTGRES_USER,
    database: process.env.POSTGRES_DB,
    password: process.env.POSTGRES_PASSWORD,
    environment: process.env.POSTGRES_ENVIRONMENT || 'development',
  },
}
